/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "theme-dev",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "theme",
            "endpoint": "https://3g5ic7i5ee.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:ca94a369-5a07-490a-8f96-03d69af6f1f1",
    "aws_cognito_region": "us-west-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "409219a712e94731960c1a9f78d34ff4",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
